import { useCallback, useEffect, useState, useMemo } from 'react'
import DataTable, { Alignment, TableColumn } from 'react-data-table-component'
import Badge from 'react-bootstrap/Badge'
import { useIntl } from 'react-intl'
import { BsPencilSquare, BsTrash } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { GrSearchAdvanced } from 'react-icons/gr'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { CiImageOn } from 'react-icons/ci'
import Col from 'react-bootstrap/Col'
import { Loading } from '../../Globals/Loading'
import { RootState } from '../../../state/store'
import Service from '../../../services'
import { ShowAlert } from '../../Globals'
import { FilterComponent } from './FilterComponent'
import './styleSmartImgs.css'
import { useClientContext } from '../../../contexts/ClientContext'

export default function SmartImagesList() {
  const { selectedScript } = useClientContext()
  const user = useSelector<RootState, any>((state) => state.userAuth)
  const [rows, setRows] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  // const [paginationResetDefaultPage, setPaginationResetDefaultPage] =
  // useState<boolean>(false)
  const [sortDir, setSortDir] = useState<string>('desc')
  const [sortField, setSortField] = useState<string>('dateAdded')
  const [filterText, setFilterText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [perPage, setPerPage] = useState<number>(100)
  const [data, setData] = useState<any[]>([])
  const intl = useIntl()
  const navigate = useNavigate()

  const setPerPageHandler = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // setLoading(true)
      setPage(1)
      setPerPage(parseInt(e.target.value))
      // setFilterText('')

      const selc =
        document.querySelectorAll('select[aria-label="MOSTRANDO"]').length > 0
          ? document.querySelectorAll('select[aria-label="MOSTRANDO"]')
          : document.querySelectorAll('select[aria-label="SHOW"]')
      const select = document.getElementById(
        'options-per-page'
      ) as HTMLSelectElement
      for (let i = 0; i < select.options.length; i++) {
        if (select.options[i].text === e.target.value) {
          const yourSelect = selc[0] as HTMLSelectElement
          yourSelect.options[i].selected = true
          const ev = new Event('change', { bubbles: true })
          yourSelect.dispatchEvent(ev)

          break
        }
      }

      // setLoading(false)
    },
    []
  )

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      // setPaginationResetDefaultPage(!paginationResetDefaultPage)
      setFilterText('')
    }

    return (
      <FilterComponent
        onFilter={setFilterText}
        onClear={handleClear}
        filterText={filterText}
        setPerPage={setPerPageHandler}
        perPage={perPage}
      />
    )
  }, [filterText, perPage, page])

  const deleteSmartImage = useCallback(
    async (id, tokenGoogle) => {
      try {
        setLoading(true)
        const { data, status } = await Service.post<any, any>(
          `/Automation/DeleteSmartImage?idHashScript=${selectedScript?.idHashScript}&id=${id}`,
          {},
          {
            headers: {
              token: user.data.token,
              username: user.data.username,
              tokenGoogle,
              'Content-Type': 'application/json',
              Accept: '*/*',
              credentials: 'include'
            }
          }
        )
        if (data.StatusCode === 0 && status == 200) {
          getSmartImages()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    },
    [selectedScript]
  )

  const getNewToken = useCallback(async (id) => {
    try {
      const { value, isConfirmed } = await ShowAlert({
        title: intl.formatMessage({
          id: 'app.modal.token.title',
          defaultMessage: 'Ingrese el <strong>token</strong>'
        }),
        input: 'text',
        html:
          '<span class="m-4 text-left">' +
          intl.formatMessage({
            id: 'app.modal.token.texto',
            defaultMessage: 'Ingrese el <strong>token</strong>'
          }) +
          '</span>',
        inputLabel: '',
        inputPlaceholder: 'Token',
        color: '#1c684c',
        confirmButtonText: intl.formatMessage({
          id: 'app.modal.btn.enviar',
          defaultMessage: 'Enviar'
        }),
        showCancelButton: true,
        cancelButtonText: intl.formatMessage({
          id: 'app.modal.btn.cerrar',
          defaultMessage: 'Cerrar'
        }),
        width: 600,
        inputValidator: (result) => {
          if (result === '') {
            return intl.formatMessage({
              id: 'app.token-inexistente',
              defaultMessage: 'El token no fue informado.'
            })
          } else {
            return ''
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (isConfirmed && value !== '') {
        deleteSmartImage(id, value)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getSmartImages = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await Service.get<any>(
        `Automation/GetSmartImages?idHashScript=${selectedScript?.idHashScript}&page=${page}&per_page=${perPage}&filterText=${filterText}&sortField=${sortField}&sortDirection=${sortDir}`,
        {
          headers: {
            token: user.data.token
          }
        }
      )
      if (data.StatusCode < 1) {
        setData(data.Entities)
        setRows(parseInt(data.Message))
      }
      if (data.StatusCode === -9) {
        ShowAlert({
          title: 'Listado de Imágenes inteligentes',
          text: 'Modulo en uso por otro usuario',
          icon: 'info',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (
        error.response.data === -9 ||
        error.response.headers.statuscode === '-9'
      ) {
        ShowAlert({
          title: 'Listado de Imágenes inteligentes',
          text: 'Modulo en uso por otro usuario',
          icon: 'info',
          showConfirmButton: true,
          timer: 2500,
          closeButtonHtml: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          confirmButtonText: intl.formatMessage({
            id: 'app.modal.btn.cerrar',
            defaultMessage: 'Cerrar'
          }),
          toast: true,
          width: 500
        })
      }
    }
  }, [selectedScript, filterText, perPage, page, sortDir, sortField])

  const zoomImg = useCallback((url) => {
    ShowAlert({
      imageHeight: 'auto',
      imageUrl: url,
      imageWidth: 600
    })
  }, [])

  const columns: Array<TableColumn<any>> = [
    {
      id: 'id',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column1',
        defaultMessage: 'Id'
      }),
      selector: (row: any) => row.id,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'id',
      minWidth: '80px'
    },
    {
      id: 'title',
      name: intl.formatMessage({
        id: 'app.smart.images.identifyText',
        defaultMessage: 'Texto identificador'
      }),
      selector: (row: any) => row.title,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'title',
      minWidth: '80px'
    },
    {
      id: 'tags',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column2',
        defaultMessage: 'Etiquetas'
      }),
      cell: (row: any) => {
        return (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {row.tags.map((tag: string, idx: number) => (
              <Badge
                key={idx}
                bg="secondary"
                style={{
                  fontWeight: 300,
                  marginRight: 6,
                  fontSize: 12,
                  cursor: 'pointer',
                  marginBottom: 4
                }}
                onClick={() => setFilterText(tag)}
              >
                {tag}
              </Badge>
            ))}
          </div>
        )
      },
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'tags',
      minWidth: '80px'
    },
    {
      id: 'userAdd',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column5',
        defaultMessage: 'Usuario'
      }),
      selector: (row: any) => row.userAdd,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'userAdd',
      minWidth: '80px'
    },
    {
      id: 'main-thumb',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column3',
        defaultMessage: 'Imagen principal'
      }),
      // selector: (row: any) => row.mainThumb,
      cell: (row) => {
        return (
          <div
            style={{
              height: 80,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
              onClick={() => zoomImg(row.urlmain)}
            >
              <GrSearchAdvanced size={20} color="rgb(47, 173, 126)" />
            </div>
            <img
              src={row.urlmain.replace(/.jpg/i, '_th.jpg')}
              alt=""
              style={{
                height: 50,
                borderRadius: '50%'
              }}
            />
          </div>
        )
      },
      reorder: true,
      center: true,
      sortField: 'main-thumb',
      minWidth: '80px'
    },
    {
      id: 'mobile-thumb',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column4',
        defaultMessage: 'Imagen móvil'
      }),
      cell: (row) => {
        return (
          <div
            style={{
              height: 80,
              display: 'flex',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
              onClick={() => zoomImg(row.urlmobile)}
            >
              <GrSearchAdvanced size={20} color="rgb(47, 173, 126)" />
            </div>
            <img
              src={row.urlmobile.replace(/.jpg/i, '_th.jpg')}
              alt=""
              style={{
                height: 50,
                borderRadius: '50%'
              }}
            />
          </div>
        )
      },
      reorder: true,
      center: true,
      sortField: 'mobile-thumb',
      minWidth: '80px'
    },
    {
      id: 'dateAdded',
      name: intl.formatMessage({
        id: 'app.smart.images.table.column6',
        defaultMessage: 'Fecha'
      }),
      selector: (row: any) => row.dateAdded,
      sortable: true,
      reorder: true,
      center: true,
      sortField: 'dateAdded',
      minWidth: '80px'
    },
    {
      id: 'actions',
      name: intl.formatMessage({
        id: 'app.cdp.audience.table.column8',
        defaultMessage: 'Acciones'
      }),
      cell: (row) => {
        return (
          <>
            <BsPencilSquare
              className="smart-icons"
              size={20}
              cursor={'pointer'}
              data-tip={'Update'}
              style={{ marginRight: 8 }}
              color="#2eac7e"
              onClick={() =>
                navigate(
                  `/dashboard/smart-images/upload-images-update/${row.id}`
                )
              }
            />

            <BsTrash
              className="smart-icons"
              cursor={'pointer'}
              size={20}
              color="#F29718"
              onClick={() => {
                getNewToken(row.id)
              }}
              data-tip={'Delete'}
            />
            <ReactTooltip delayShow={10} data-backgroundColor={'#2fad7e'} />
          </>
        )
      },
      reorder: true,
      center: true,
      sortField: 'mobile-thumb',
      minWidth: '80px'
    }
  ]

  const paginationComponentOptions = {
    rowsPerPageText: intl.formatMessage({
      id: 'app.vitrinas.mostrando',
      defaultMessage: 'MOSTRANDO'
    }),
    rangeSeparatorText: intl.formatMessage({
      id: 'app.vitrinas.de',
      defaultMessage: ' DE '
    })
  }
  useEffect(() => {
    getSmartImages()
  }, [perPage, filterText, page, sortDir, sortField])

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <Col>
          <div>
            <div
              style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}
            >
              <div
                style={{
                  height: 40,
                  width: 40,
                  borderRadius: '100%',
                  backgroundColor: '#2BAE7E',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 12
                }}
              >
                <CiImageOn size={24} color="#fff" />
              </div>
              <h2 style={{ fontSize: 18, margin: 0, color: '#444' }}>
                Listado de Imágenes
              </h2>
            </div>
          </div>
          <button
            className="ppal_button btn btn-primary"
            id="add-new-audience"
            onClick={() => navigate('/dashboard/smart-images/upload-images/')}
          >
            Subir Imágenes
          </button>
          <div
            style={{
              backgroundColor: '#fff',
              padding: '.5rem 2rem',
              marginTop: 20
            }}
          >
            <DataTable
              subHeaderAlign={Alignment.RIGHT}
              columns={columns}
              data={data}
              progressPending={loading}
              progressComponent={
                <Loading
                  textLoading={intl.formatMessage({
                    id: 'app.vitrinas-spinner.espere',
                    defaultMessage: 'Espere...'
                  })}
                />
              }
              pagination
              paginationServer
              paginationPerPage={100}
              paginationTotalRows={rows}
              paginationResetDefaultPage={true}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 100]}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChangeRowsPerPage={(first, second) => {
                setPerPage(first)
                setPage(second)
              }}
              onChangePage={(e) => setPage(e)}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={{
                subHeader: {
                  style: {
                    justifyContent: 'space-between',
                    marginBottom: '1rem',
                    padding: '0 1rem !important'
                  }
                },
                pagination: {
                  style: {
                    color: '#000',
                    fontSize: '14px',
                    minHeight: '56px',
                    backgroundColor: 'transparent',
                    // borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    borderTopColor: '#ddd'
                  },
                  pageButtonsStyle: {
                    borderRadius: '50%',
                    height: '40px',
                    width: '40px',
                    padding: '8px',
                    margin: '5px',
                    cursor: 'pointer',
                    transition: '0.4s',
                    color: '#fff',
                    // fill: theme.button.default,
                    backgroundColor: '#e9ecef',
                    '&:disabled': {
                      cursor: 'unset',
                      color: '#2fad7e'
                      // fill: theme.button.disabled,
                    },
                    '&:hover:not(:disabled)': {
                      backgroundColor: '#2fad7e',
                      color: '#fff'
                    },
                    '&:focus': {
                      outline: 'none',
                      backgroundColor: '#fff'
                    }
                  }
                }
              }}
              // defaultSortFieldId={1}
              noDataComponent={intl.formatMessage({
                id: 'app.vitrinas.nodata',
                defaultMessage: 'Sin Resultados.'
              })}
              responsive
              sortServer
              onSort={(column: any, order) => {
                setSortField(column.sortField)
                setSortDir(order)
              }}
            />
          </div>
        </Col>
      </div>
    </div>
  )
}
